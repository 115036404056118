<template>
	<div>
		<div class="pd20" style="height: calc(100vh - 60px);">
			<div class="pd20 bg-w" style="height: 100%;">
				<!-- <a  class="tab-btn-order-index " type="primary" @click="$router.push('/order/refund')" >退款</a> -->
				<div class="flex center ft18 cl-main">订单列表</div>
				<div class="mt20">
					<a-table :columns="columns" :data-source="datas" rowKey="order_id" :scroll="{x:'calc(100%)',y: 700}"
					:pagination="pagination" @change="handleTableChange" :loading="loading">
		
						<div slot="skus" slot-scope="skus,record">
							<div v-if="skus.length>0">
								<div v-for="(item,index) in skus" :class="{mt5:index>0}">
									商品：{{item.goods_name}}{{item.goods_sku_id>0 ? '('+item.goods_sku_name+')' : ''}}
								</div>
							</div>
							<div v-else>暂无商品</div>
						</div>
						<div slot="member" slot-scope="member,record">
							{{member ? member.nick_name+"("+member.mobile+")" : '游客'}}
						</div>
		
						<div class="flex center" slot="action" slot-scope="record">
							<div>
								<a-space direction="vertical">
									<a-button type="primary"  @click="getDetailAct(record)">查看详情</a-button>
									<a-button type="defalut" v-if="record.status==1" @click="cancelOrderAct(record)">取消订单</a-button>
									<a-button type="defalut"  @click="cancelOrderdeleteAct(record)">删除订单</a-button>
								</a-space>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		data() {
			return {
				loading:false,
				columns: [
				{
					title: '订单编号',
					dataIndex: 'order_id',
					align: 'center',
					
				},	
				{
						title: '商品信息',
						dataIndex: 'skus',
						align: 'center',
						width: 300,
						scopedSlots: {
							customRender: 'skus'
						}
					},
					{
						title: '商品数量',
						dataIndex: 'total_num',
						align: 'center',
						// ellipsis: true
					},
					{
						title: '会员名称',
						dataIndex: 'member',
						align: 'center',
						width: 200,
						scopedSlots: {
							customRender: 'member'
						}
					},
					{
						title: '支付/元',
						dataIndex: 'need_pay',
						align: 'center',
						// ellipsis: true
					},
					{
					title: '实付金额',
						dataIndex: 'true_pay',
						align: 'center',
					},
					{
					title: '备注',
						dataIndex: 'remark',
						align: 'center',
					},
					{
						title: '支付方式',
						dataIndex: 'pay_type_mean',
						align: 'center',
						// ellipsis: true
					},
					{
						title: '支付状态',
						dataIndex: 'status_means',
						align: 'center',
						// ellipsis: true
					},
					{
						title: '创建时间',
						dataIndex: 'add_time_format',
						align: 'center',
						// ellipsis: true
					},
					{
						title: '操作',
						key: 'action',
						align: 'center',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				datas: [],
			}
		},
		methods:{
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('getOrderList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			cancelOrderAct(record){
				this.$confirm({
					title:'确定取消这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('cancelOrder',{
								order_id:record.order_id,
							}).then(res=>{
								this.$message.success('取消成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			//删除订单
			cancelOrderdeleteAct(record){
				this.$confirm({
					title:'确定删除这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('cancelOrder',{
								order_id:record.order_id,
								type:1
							}).then(res=>{
								this.$message.success('删除成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			getDetailAct(record){
				this.$router.push('/order/detail?id='+record.order_id)
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.c{
		width: calc(100%);
	}
	.tab-btn-order-index{
		display: block;
    color: #fff;
    background-color: #4772FF;
    border-color: #4772FF;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    width: 100px;
    height: 35px;
    border-radius: 8px;
    text-align: center;
    line-height: 35px;
	}
</style>
